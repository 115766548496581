import { styledComponent } from '@hermes/web-components'
import Section from '../../Section'

export const CustomersThinkWrapper = styledComponent(Section)(({ theme }) => ({
  background: theme.palette.additional.paperLinear,
  backgroundImage: 'url(/img/background-patterns/desktop/our-customers-section.svg)',
  backgroundRepeat: 'no-repeat',
  backgroundPositionX: 'center',
  [theme.breakpoints.down('sm')]: {
    backgroundImage: 'url(/img/background-patterns/mobile/our-customers-section.svg)',
    backgroundPositionX: 'left',
    backgroundPositionY: 'bottom'
  }
}))
