import { Container, styledComponent } from '@hermes/web-components'

export const ClutchAchievementsContainer = styledComponent(Container)<{
  component?: any
}>(({ theme }) => ({
  position: 'relative',
  paddingLeft: '0 !important',
  paddingRight: '0 !important',
  '&:after': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '200px',
    height: '100%',
    zIndex: 1,
    pointerEvents: 'none',
    background: 'linear-gradient(90deg, #fff 10%, rgba(255, 255, 255, 0) 100%)'
  },
  '&:before': {
    content: '""',
    position: 'absolute',
    top: 0,
    right: 0,
    width: '200px',
    height: '100%',
    zIndex: 1,
    pointerEvents: 'none',
    background: 'linear-gradient(-90deg, #fff 10%, rgba(255, 255, 255, 0) 100%)'
  },
  [theme.breakpoints.down('sm')]: {
    '&:after': {
      display: 'none'
    },
    '&:before': {
      display: 'none'
    }
  }
}))
