import { Box, styledComponent, Typography } from '@hermes/web-components'

export const TrustedByReviewWrapper = styledComponent(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  border: `0.2px solid ${theme.palette.additional.border}`,
  borderRadius: '20px',
  padding: '30px',
  minWidth: '260px',
  display: 'grid',
  height: '100%'
}))

export const ReviewTitle = styledComponent(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
  maxHeight: '43px',
  'p, time': {
    fontSize: '14px',
    lineHeight: '17px',
    color: theme.palette.typography.secondary
  }
}))

export const ReviewContent = styledComponent(Typography)(() => ({
  marginTop: '26px',
  marginBottom: '40px',
  fontSize: '14px',
  lineHeight: '22px',
  whiteSpace: 'pre-line'
}))

export const ReviewerWrapper = styledComponent(Box)(({ theme }) => ({
  display: 'flex',
  gap: '25px',
  alignItems: 'center',
  alignSelf: 'flex-end',
  img: {
    borderRadius: '50%',
    objectFit: 'cover'
  },
  div: {
    p: {
      fontSize: '16px',
      lineHeight: '20px',
      marginBottom: '7px'
    },
    a: {
      fontSize: '14px',
      lineHeight: '18px',
      color: theme.palette.typography.secondary
    }
  }
}))
