import { Container, styledComponent } from '@hermes/web-components'
import Section from '../../Section'

export const TrustedBySectionWrapper = styledComponent(Section)(() => ({
  backgroundImage: 'url(/img/background-patterns/desktop/partners-section.svg)',
  backgroundRepeat: 'no-repeat',
  backgroundPositionX: 'center'
}))

export const TrustedByIconsCarouselsContainer = styledComponent(Container)(({ theme }) => ({
  position: 'relative',
  '&:after': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '300px',
    height: '100%',
    zIndex: 1,
    pointerEvents: 'none',
    background: 'linear-gradient(90deg, #fff 10%, rgba(255, 255, 255, 0) 100%)'
  },
  '&:before': {
    content: '""',
    position: 'absolute',
    top: 0,
    right: 0,
    width: '300px',
    height: '100%',
    zIndex: 1,
    pointerEvents: 'none',
    background: 'linear-gradient(-90deg, #fff 10%, rgba(255, 255, 255, 0) 100%)'
  },
  [theme.breakpoints.down('sm')]: {
    '&:after': {
      width: '150px'
    },
    '&:before': {
      width: '150px'
    }
  }
}))
