import { Carousel, CarouselSlide, Link } from '@hermes/web-components'
import { useInView as useIntersectionObserver } from 'react-intersection-observer'
import { StrapiMedia, StrapiResponse } from '../../../types/strapi'
import Section from '../../Section'
import SlideHeader from '../../SlideHeader'
import StrapiImage from '../../StrapiImage'
import { ClutchAchievementsContainer } from './styles'

interface ClutchAchievementProps {
  id: string
  url: string
  image: StrapiResponse<StrapiMedia>
}

interface ClutchAchievementsProps {
  clutchTitle: string
  clutchAchievements: ClutchAchievementProps[]
}

const ClutchAchievements = ({ clutchTitle, clutchAchievements }: ClutchAchievementsProps) => {
  const { ref, inView } = useIntersectionObserver({
    triggerOnce: true,
    rootMargin: '0px 0px 200px 0px'
  })

  return (
    <Section ref={ref} id="clutch-achievements">
      {clutchTitle && <SlideHeader headingTitle={clutchTitle} />}
      <ClutchAchievementsContainer>
        {inView && (
          <Carousel
            slidesPerView={5}
            spaceBetween={30}
            slidesPerGroup={1}
            updateOnMove
            pagination={false}
            lazyLoad
            autoScroll
            breakpoints={{
              1380: {
                perPage: 5
              },
              1080: {
                perPage: 4
              },
              780: {
                perPage: 3
              },
              480: {
                perPage: 2
              }
            }}
            loop
          >
            {clutchAchievements.map(({ id, url, image }) => (
              <CarouselSlide
                key={`clutch-achievement-${id}`}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                {url?.length ? (
                  <Link href={url} target="_blank">
                    <StrapiImage
                      image={image}
                      sx={{
                        objectFit: 'contain',
                        width: '160px',
                        height: '160px'
                      }}
                    />
                  </Link>
                ) : (
                  <StrapiImage
                    image={image}
                    sx={{
                      objectFit: 'contain',
                      width: '160px',
                      height: '160px'
                    }}
                  />
                )}
              </CarouselSlide>
            ))}
          </Carousel>
        )}
      </ClutchAchievementsContainer>
    </Section>
  )
}

export default ClutchAchievements
