import { Link, Carousel, CarouselSlide } from '@hermes/web-components'
import React from 'react'
import { StrapiMedia, StrapiResponse } from '../../../types/strapi'
import { TrustedBySectionWrapper, TrustedByIconsCarouselsContainer } from './styles'
import SlideHeader from '../../SlideHeader'
import StrapiImage from '../../StrapiImage'

interface TrustedBySectionProps {
  icons: {
    url: string
    image: StrapiResponse<StrapiMedia>
  }[]
  title: string
}

const TrustedByLogoLink = ({ url, image }: { url: string; image: StrapiResponse<StrapiMedia> }) => (
  <Link
    href={url}
    target="_blank"
    rel="nofollow"
    sx={{
      position: 'relative',
      height: image.data.attributes?.height || 0 > 45 ? 65 : image.data.attributes.height,
      flex: 1,
      width: '100%',
      minWidth: '164px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }}
  >
    <StrapiImage
      image={image}
      alt={image.data.attributes.name}
      sx={{
        objectFit: 'contain',
        width: '150px',
        height: '45px'
      }}
      lazy="carousel"
    />
  </Link>
)

const TrustedBySection = ({ icons, title }: TrustedBySectionProps) => {
  const firstHalfOfLogos = icons.slice(0, Math.ceil(icons.length / 2))
  const secondHalfOfLogos = icons.slice(Math.ceil(icons.length / 2), icons.length)

  const logosArray = [firstHalfOfLogos, secondHalfOfLogos]

  return (
    <TrustedBySectionWrapper id="trusted-by">
      <SlideHeader headingTitle={title} />
      <TrustedByIconsCarouselsContainer>
        {logosArray.map((logos, index) => (
          <Carousel
            key={`trusted-by-carousel-${index}`}
            slidesPerView={6}
            slidesPerGroup={1}
            spaceBetween={30}
            updateOnMove
            pagination={false}
            autoScroll
            reverseAutoScroll={index === 1}
            loop
            lazyLoad
            speed="slower"
            sx={{
              marginBottom: '30px'
            }}
            breakpoints={{
              1024: {
                perPage: 4
              },
              767: {
                perPage: 3
              },
              640: {
                perPage: 2
              }
            }}
          >
            {logos?.map(({ url, image }) => (
              <CarouselSlide key={`trusted-by-icon-${url}`}>
                <TrustedByLogoLink url={url} image={image} />
              </CarouselSlide>
            ))}
          </Carousel>
        ))}
      </TrustedByIconsCarouselsContainer>
    </TrustedBySectionWrapper>
  )
}

export default TrustedBySection
