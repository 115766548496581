import { Box, Carousel, CarouselSlide, Container } from '@hermes/web-components'
import TrustedByReview, { TrustedByReviewProps } from './Review'
import SlideHeader from '../../SlideHeader'
import { CustomersThinkWrapper } from './styles'
import ClutchWidget from './ClutchWidget'
import { StrapiAttributes, StrapiResponse } from '../../../types/strapi'

interface CustomersThinkProps {
  title: string
  reviews: StrapiResponse<StrapiAttributes<TrustedByReviewProps>[]>
}

const CustomersThink = ({ title, reviews }: CustomersThinkProps) => (
  <>
    <Box
      sx={{
        overflow: 'hidden',
        position: 'absolute',
        width: 0,
        height: 0
      }}
    >
      <ClutchWidget />
    </Box>
    <CustomersThinkWrapper id="customers-think">
      <SlideHeader headingTitle={title} />
      <Container>
        <Carousel
          slidesPerView={3}
          breakpoints={{
            1024: {
              perPage: 3
            },
            767: {
              perPage: 2
            },
            640: {
              perPage: 1
            }
          }}
          spaceBetween={45}
          navigation
        >
          {reviews.data
            .sort(
              ({ attributes: xAttr }, { attributes: yAttr }) =>
                Number(yAttr.reviewedOnClutch) - Number(xAttr.reviewedOnClutch)
            )
            .map(({ attributes }, index) => (
              <CarouselSlide key={`review-${index}-mobile`}>
                <TrustedByReview {...attributes} />
              </CarouselSlide>
            ))}
        </Carousel>
      </Container>
    </CustomersThinkWrapper>
  </>
)

export default CustomersThink
